.education-content{
    padding: 0 45rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 5rem 0;
    gap: 7rem;
    transition: display 4s ease-in;

    .education-timeline{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(var(--color-3-rgb), .7);
        border-radius: .6rem;

        .education-timeline-title{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: rgba(var(--color-3-rgb), 1);
            border-radius: .6rem;
            padding: 1rem;
            margin: 0 0 1rem 0;
            

            span{
                font-family: var(--font-3);
                color: var(--color-2);
                font-size: 2rem;
                font-weight: 400;
                
            }
        }
        

        .education-items{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 1rem 1rem 1rem;

            .education-items-row{
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                background-color: rgba(var(--color-3-rgb), 1);
                border-radius: .6rem;
                margin: 1rem 0;
                overflow: hidden;

                :hover::before{
                    content: '';
                    position: absolute;
                    width: 1rem;
                    height: 100%;
                    top: 0;
                    left: -.7rem;
                    background-color: rgba( var(--color-4-rgb), .8);
                }

                h1{
                    font-family: var(--font-1);
                    color: rgba(var(--color-2-rgb), .8);
                    font-size: 1.8rem;
                    font-weight: 600;
                    padding: 1rem;
                }

                h2{
                    font-family: var(--font-1);
                    color: rgba(var(--color-1-rgb), .8);
                    font-size: 1.8rem;
                    font-weight: 600;
                    padding: 0 1rem;
                }

                span{
                    display: flex;
                    // align-self: flex-end;
                    font-family: var(--font-3);
                    color: rgba( var(--color-4-rgb), 1);
                    font-size: 1.6rem;
                    font-weight: 400;
                    padding: 1rem;
                }
            }
        }
    }
}

@media screen and (min-width: 3000px) {

}

@media screen and (max-width: 2000px) {
    .education-content{
        padding: 0 30rem;
    }
}

/* laptops, pantallas, grandes */
@media screen and (max-width: 1600px) {
    .education-content{
        padding: 0 15rem;
        gap: 5rem;
    }
}

/* laptops, pantallas, grandes */
@media screen and (max-width: 1200px) {
    .education-content{
        padding: 0 5rem;
    }
}

/* laptops, pantalladas medianas */
@media screen and (max-width: 992px) {

}

/* Tabletas medianas */
@media screen and (max-width: 768px) {
    
}

/* Tabletas, telefonos */
@media screen and (max-width: 600px) {
    .education-content{
        padding: 0 2rem;
    }
}

/* Telefonos modernos */
@media screen and (max-width: 500px) {

}

/* Telefonos modernos */
@media screen and (max-width: 400px) {

}