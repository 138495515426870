.about-content{
    position: relative;
    padding: 0 45rem;
    width: 100%;
    margin-top: 5rem;


    .about-description{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5rem;
    

        >div{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: rgba(var(--color-3-rgb), .7);
            border-radius: .6rem;
            margin-bottom: 2rem;

            > span{
                width: 100%;
                display: flex;
                font-family: var(--font-3);
                color: var(--color-2);
                background-color: rgba(var(--color-3-rgb), 1);
                border-radius: .6rem;
                font-size: 2rem;
                font-weight: 400;
                margin: 0 0 2rem 0;
                padding: 1rem;
            }

            > p{
                // width: claem)c(100% - 2r;
                display: flex;
                font-family: var(--font-1);
                color: rgba(var(--color-1-rgb), .9);
                background-color: rgba(var(--color-3-rgb), 1);
                border-radius: .6rem;
                font-size: 1.8rem;
                font-weight: 400;
                margin: 0 1rem 2rem 1rem;
                padding: 1rem 1rem;
            }
        }

        .about-genral-info{
            // width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: rgba(var(--color-3-rgb), 1);
            border-radius: .6rem;
            margin: 0 1rem 1rem 1rem;
            padding: 1rem;

            .about-genral-info-row{
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .about-genral-info-label{
                    width: calc(1rem + 30% + 1rem);
                    font-family: var(--font-2);
                    color: rgba(var(--color-1-rgb), 1);
                    font-size: 1.8rem;
                    font-weight: 900;
                    margin: 0 0 2rem 0;
                    // padding: 0 1rem;
                }

                .about-genral-info-content{
                    width: calc(1rem + 60% + 1rem);
                    font-family: var(--font-2);
                    color: rgba(var(--color-1-rgb), .8);
                    font-size: 1.8rem;
                    font-weight: 400;
                    margin: 0 0 2rem 0;
                    padding: 0 2rem;
                }
            }
        }
    }
}


@media screen and (min-width: 3000px) {

}

@media screen and (max-width: 2000px) {
    .about-content{
        padding: 0 30rem;
    }
}

/* laptops, pantallas, grandes */
@media screen and (max-width: 1600px) {
    .about-content{
        padding: 0 15rem;
    }
}

/* laptops, pantallas, grandes */
@media screen and (max-width: 1200px) {
    .about-content{
        padding: 0 5rem;
    }
}

/* laptops, pantalladas medianas */
@media screen and (max-width: 992px) {

}

/* Tabletas medianas */
@media screen and (max-width: 768px) {

}

/* Tabletas, telefonos */
@media screen and (max-width: 600px) {
    .about-content{
        padding: 0 2rem;
    }
}

/* Telefonos modernos */
@media screen and (max-width: 500px) {

}

/* Telefonos modernos */
@media screen and (max-width: 400px) {

}