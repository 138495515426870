 .jelly-content{
    padding: 0 35rem;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10rem 0;
    gap: 5rem;

    > div{
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: rgba(var(--color-3-rgb), .7);
        border-radius: .6rem;
    }

    .jelly-title{
        h1{
            width: 100%;
            display: flex;
            font-family: var(--font-3);
            color: var(--color-2);
            font-size: 2rem;
            font-weight: 400;
            margin: 0 0 2rem 0;
            padding: 1rem 1rem;
            background-color: rgba(var(--color-3-rgb), 1);
            border-radius: .6rem;
        }
    }

    .jelly-body{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 1rem 1rem 1rem;
        gap: 1rem;

        .jelly-body-image{
            width: 70rem;
            // height: 45%;
            border-radius: .6rem;
            overflow: hidden;
            background-image: url('./../../images/projects/JellyWebPortada.PNG') ;
            background-repeat: no-repeat;
            background-size: 82rem auto;
        } 

        .jelly-body-details{
            width: 100%;
            // height: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            // padding: 0 1rem 0 1rem; 
            // flex-wrap: wrap;

            div h2{
                font-family: var(--font-3);
                color: rgba( var(--color-4-rgb), 1);
                font-size: 2rem;
                font-weight: 600;
                // text-align: center;
                padding: 1rem 1rem ;
            }

            .jelly-body-details-content{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                gap: 1rem;

                div{
                    width: 50%;
                    background-color: rgba(var(--color-3-rgb), 1);
                    border-radius: .6rem;

                    ul{
                        padding: 0 1rem 1rem 3rem;
                        list-style: none;
                        

                        li{
                            // padding: 0 1rem;
                            font-family: var(--font-1);
                            color: rgba(var(--color-1-rgb), .8);
                            font-size: 1.6rem;
                            font-weight: 600;
                            position: relative;     
                        }
                        
                        li::before{
                            content: '';
                            background-color: rgba(var(--color-1-rgb), .8);
                            height: 2px;
                            position: absolute;
                            top: 13px;
                            left: -15px;
                            width: 10px;
                        }
                    }
                }
            }

            .jelly-body-details-description{
                width: 100%;
                background-color: rgba(var(--color-3-rgb), 1);
                border-radius: .6rem;

                p{
                    width: 100%;
                    padding: 0 1rem 1rem 1rem;
                    font-family: var(--font-1);
                    color: rgba(var(--color-1-rgb), .8);
                    font-size: 1.6rem;
                    font-weight: 600;
                    margin-bottom: 1rem;

                    span{
                        width: 100%;
                        position: relative;
                        display: block;
                        font-family: var(--font-1);
                        color: rgba(var(--color-1-rgb), .8);
                        font-size: 1.6rem;
                        font-weight: 600;
                        margin-left: 1rem;
                    }

                    span::before{
                        content: '';
                        position: absolute;
                        width: 5px;
                        height: 2px;
                        top: 10px;
                        left: -10px;
                        background-color: rgba(var(--color-1-rgb), .8);
                    }
                }
            }
        }
    }

    .jelly-footer {
        display: flex;
        flex-direction: column;
        padding: 0 1rem 1rem 1rem;
    
        div{
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: rgba(var(--color-3-rgb), 1);
            border-radius: .6rem;
            padding: 1rem;

            h2{
                font-family: var(--font-3);
                color: rgba( var(--color-4-rgb), 1);
                font-size: 2rem;
                font-weight: 600;
                margin-bottom: 1rem;
                // padding: 1rem ;
            }

            p{
                width: 100%;
                // padding: 0 1rem 1rem 1rem;
                font-family: var(--font-1);
                color: rgba(var(--color-1-rgb), .8);
                font-size: 1.6rem;
                font-weight: 600;
                margin: 1rem 0;
            }

            div{
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 0;

                a{
                    display: flex;
                    background-color: #0b8043;
                    border-radius: 1.6rem;
                    text-align: center;
                    font-family: var(--font-1);
                    color: rgba(var(--color-1-rgb), .9);
                    font-size: 1.6rem;
                    font-weight: 600;
                    padding: 0.5rem 1rem;
                    margin: 1rem 0;
                    border: none;
                    cursor: pointer;
                    text-decoration: none;
                    transition: all 0.4s ease-in-out;
                }

                a:hover{
                    padding: 0.5rem 2rem;
                }
            }
        }
    }

}

@media screen and (min-width: 3000px) {

}

@media screen and (max-width: 2000px) {
    .jelly-content{
        padding: 0 25rem;
    }
}

/* laptops, pantallas, grandes */
@media screen and (max-width: 1600px) {
    .jelly-content{
        padding: 0 10rem;
    }
}

/* laptops, pantallas, grandes */
@media screen and (max-width: 1200px) {
    .jelly-content{
        padding: 0 5rem;

        div{
            .jelly-body{
                flex-direction: column;

                .jelly-body-image{
                    width: 100%;
                    height: 30rem;
                    // background-size: auto ;
                    background-size: 100% auto;
                }

                .jelly-body-details{
                    justify-content: space-between;
                }
            }
        }
    }
}

/* laptops, pantalladas medianas */
@media screen and (max-width: 992px) {

}

/* Tabletas medianas */
@media screen and (max-width: 768px) {

}

/* Tabletas, telefonos */
@media screen and (max-width: 600px) {
    .jelly-content{
        padding: 0 2rem;

        div{
            .jelly-body{
                .jelly-body-details{
                    .jelly-body-details-content{
                        flex-direction: column;

                        div{
                            width: 100%;
                        }

                    }
                }

                // .jelly-body-image{
                //     background-size: auto 100% ;
                // }
            }
        }
    }
}

/* Telefonos modernos */
@media screen and (max-width: 500px) {
    .jelly-content{

        div{
            .jelly-body{
                .jelly-body-image{
                    background-size: auto 100% ;
                }
            }
        }
    }
}

/* Telefonos modernos */
@media screen and (max-width: 400px) {

}