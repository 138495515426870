.contact{
    position: relative;
    padding: 0 45rem;
    width: 100%;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem 0 10rem 0;
    gap: 7rem;
    
 
    .contact-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: rgba(var(--color-3-rgb), .7);
        border-radius: .6rem;

        > h1{
            width: 100%;
            display: flex;
            font-family: var(--font-3);
            color: var(--color-2);
            font-size: 2rem;
            font-weight: 400;
            margin: 0 0 2rem 0;
            padding: 1rem 1rem;
            background-color: rgba(var(--color-3-rgb), 1);
            border-radius: .6rem;
        }

        .contact-content-desc{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 0 1rem 0 1rem;

            p{
                font-family: var(--font-1);
                color: rgba(var(--color-1-rgb),.8);
                font-size: 1.6rem;
                font-weight: 400;
                padding: 1rem;
                border-radius: .6rem;
                background-color: rgba(var(--color-3-rgb), 1);
            }
        }

        .contact-content-items{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            a{
                width: 50%;
                margin: 1rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 1rem;
                gap: 1rem;
                justify-content: center;
                border-radius: .6rem;
                background-color: rgba(var(--color-3-rgb), 1);
                cursor: pointer;
                text-decoration: none;

                span{
                    font-family: var(--font-1);
                    color: rgba(var(--color-1-rgb),.8);
                    font-size: 2rem;
                    font-weight: 400;
                }

                .contact-icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            a:hover{
                span{
                    color: rgba(var(--color-4-rgb),.8);
                }
            }
        }
    }
}

@media screen and (min-width: 3000px) {

}

@media screen and (max-width: 2000px) {
    .contact{
        padding: 0 30rem;
    }
}

/* laptops, pantallas, grandes */
@media screen and (max-width: 1600px) {
    .contact{
        padding: 0 15rem;
        gap: 5rem;
    }
}

/* laptops, pantallas, grandes */
@media screen and (max-width: 1200px) {
    .contact{
        padding: 0 5rem;
    }
}

/* laptops, pantalladas medianas */
@media screen and (max-width: 992px) {

}

/* Tabletas medianas */
@media screen and (max-width: 768px) {
    .contact{
        .contact-content{
        .contact-content-items{
            width: 100%;
            flex-direction: column;
            padding: 1rem;
            gap: 1rem;

                a{
                    width: 100%;
                    margin: 0;
                    padding: 1rem;
                }
            }
        }
    }
}

/* Tabletas, telefonos */
@media screen and (max-width: 600px) {
    .contact{
        padding: 0 2rem;
    }
}

/* Telefonos modernos */
@media screen and (max-width: 500px) {

}

/* Telefonos modernos */
@media screen and (max-width: 400px) {

}